import { ButtonBase, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Edit, Edit2, Mail } from "react-feather";
import { Badge, Button, Card, CardBody, CardImg } from "reactstrap";
import styled from "styled-components";
import { styled as miuiStyled } from '@mui/material/styles';
import { valSwalFailure, valSwalSuccess, valSwalConfirm, swalalertWithMsg } from "../../../dev-config/global";
import { useDispatch } from "react-redux";
import { updateUserProfileAvatar } from "../store/action";

const VisuallyHiddenInput = miuiStyled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const ProfileHeader = (props) => {
  const { userProfile, formData } = props;
  const [profilePic, setProfilePic] = useState(userProfile?.user_metadata?.avatar || '/images/1.png');
  const dispatch = useDispatch()

  useEffect(() => {
    setProfilePic(userProfile?.user_metadata?.avatar)
  }, [userProfile])

  const handleProfilePicChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    const fileFormData = new FormData();
    fileFormData.append('files', file);
    fileFormData.append('user_id', userProfile?._id);
    event.target.value = null
    console.log('Selected Image:', file);

    var confirm_alert = await valSwalConfirm('Profile update', 'Are you sure you want to update your profile?', 'Confirm')
    if (confirm_alert) {
      swalalertWithMsg(true, 'Please wait!')
      dispatch(updateUserProfileAvatar(fileFormData)).then((res => {
        valSwalSuccess('Success', 'Profile updated')
        setProfilePic(URL.createObjectURL(file));  // Update the profile image preview
      })).catch(err => {
        valSwalFailure('Error', 'Something went wrong, please try again!')
      })
    } else {
      valSwalSuccess('Success', 'Profile not uploaded!')
    }
  };

  return (
    <Card >

      <CardImg top src="/images/profile-banner.png" alt="profile body" className="w-100" />
      <ImageContainer
        className="d-flex justify-content-between align-items-center flex-wrap"
        style={{ gap: "15px" }}
      >

        <div className=" d-sm-flex" style={{ gap: "20px" }}>
          <div className="position-relative" style={{ maxWidth: '120px' }}>
            <label htmlFor="profile-pic-upload" color="success" className=" edit-button btn btn-sm p-1  position-absolute" style={{ borderRadius: '0px 3px 0px 0px' }}>
              <Edit2 size={'18px'} />
            </label>
            <VisuallyHiddenInput
              type='file'
              id="profile-pic-upload"
              onChange={(event) => { handleProfilePicChange(event) }}
              accept="image/jpeg, image/png, image/jpg"
            />

            <img
              src={profilePic || '/images/1.png'}
              alt="profile-image"
              className="img-thumbnail p-2"
              width={"120px"}
            />
          </div>
          <div className="align-self-end mt-3 mt-sm-0">
            <h3>{formData?.first_name} {formData?.last_name}</h3>
            <a href="mailto:tech@firesky.cc">
              <Mail size={"14"} className="mr-1" />
              {userProfile?.email}
            </a>
          </div>
        </div>
        {/* <Button color="primary" size="sm" className="align-self-end">
          Back
        </Button> */}
      </ImageContainer>
    </Card>
  );
};

export default ProfileHeader;

export const ImageContainer = styled(CardBody)`
  @media (min-width: 576px) {
    margin-top: -55px;
  }

  .edit-button{
    right: 0;
    cursor:'pointer'; 
   
  }
  label.edit-button.btn.btn-sm.p-1.position-absolute {
    background-color: rgb(113, 221, 55);
    color: #fff;
  }
`;
