import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./utils/history";
import { getConfig } from "./config";
// Import Store & ReduxProvider
import { Store } from "./Store/store";
import { Provider } from "react-redux";
import axios from "axios";
import TagManager from "react-gtm-module";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const onRedirectCallback = (appState) => {
  if (appState) {
    history.push(appState.returnTo);
  } else {
    history.push(window.location.pathname);
  }
};

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  ...(config.audience ? { audience: config.audience } : null),
  redirectUri: window.location.origin,
  onRedirectCallback,
  scope: "openid profile email read:current_user read:current_user_metadata",
};

const tagManagerArgs = {
  gtmId: "G-XYW6VCQP7S",
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <Auth0Provider {...providerConfig}>
    <Provider store={Store}>
      <App />
    </Provider>
  </Auth0Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
