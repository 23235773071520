import axios from 'axios'

export const getAllPosts = () =>{
    return async dispatch => {
        await axios.get(`/resource/list/coa`)
            .then(response =>  {
                dispatch({
                    type: 'GET_ALL_POSTS',
                    data: response.data.data
                })
            });
    }
}

export const getPostSlug = () =>{
    return async dispatch => {
        await axios.get(`/resource/list/coa`)
            .then(response =>  {
                dispatch({
                    type: 'GET_ALL_POSTS',
                    data: response.data.data
                })
            });
    }
}

/* 
    Get All post by slug
    URL : category/slug/postname
    api : api/resource/list
*/
export const getPostBySlug = (data) => {
    return async dispatch => {
        await axios.post(`/resource/list`,data)
            .then(response =>  {
                dispatch({
                    type: 'GET_ALL_POSTS',
                    data: response.data.data
                })
            });
    }
}